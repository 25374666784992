<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        flat
      >
        <v-toolbar-title>Sim Replacement</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-text-field
          v-model="search"
          label="Enter new msisdn"
          clearable
        />
        <v-spacer />
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            class="px-1 pb-1"
            hide-actions
          >
            <template #items="props">
              <td
                v-if="props.item.msisdn"
                class="text-xs-left"
              >
                {{ props.item.msisdn }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.iccid"
                class="text-xs-left"
              >
                {{ props.item.iccid }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.newiccid"
                class="text-xs-left"
              >
                {{ props.item.newiccid }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-flex
        xs6
      >
        <v-text-field
          v-model="iccidNew"
          label="Enter new Iccid"
          outline
        />
      </v-flex>
      <v-dialog
        v-model="executeDialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on="on"
          >
            Execute Sim Replacement
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Execute Sim Replacement MNP on {{ iccidNew }}</span>
          </v-card-title>

          <v-alert
            :value="true"
            :type="executeAlertType"
            v-html="executeAlertMessage"
          />

          <v-card-actions>
            <v-btn
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeDialog = false"
            >
              {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
            </v-btn>
            <v-btn
              v-show="executeAlertType === 'info'"
              :loading="loading"
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeReplaceImsi"
            >
              Execute
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createPostParams, createGetParams } from '@/rest'
const pageTitle = 'Imsi'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data: () => ({
    title: pageTitle,
    alert: false,
    alertMessage: null,
    search: null,
    iccidNew: null,
    loading: false,
    executeDialog: false,
    executeAlertType: 'info',
    executeAlertMessage: null,
    error: null,
    items: [],
    headers: [
      {
        align: 'left',
        sortable: false,
        text: 'Msisdn',
        value: 'msisdn',
      },
      {
        align: 'left',
        sortable: false,
        text: 'Iccid',
        value: 'iccid',
      },
      {
        align: 'left',
        sortable: false,
        text: 'New Iccid',
        value: 'iccidNew',
      },
    ],
    breadcrumbs: [
      {
        text: 'Mnp', disabled: false, to: '/support/mnp',
      },
      {
        text: pageTitle, disabled: true,
      },
    ],
    addedItem: {
      msisdn: null,
      iccid: null,
      iccidNew: null,
    },
    defaultItem: {
      msisdn: null,
      iccid: null,
      iccidNew: null,
    },
  }),

  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },

  watch: {
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Click <strong>EXECUTE</strong> only when you are sure that the Iccid is relevant.</div>'
      }
    },
    search: function (val) {
      this.getImsi(val)
    },

  },

  methods: {
    getImsi (search) {
      const params = createGetParams({ search })
      this.$rest.get('getImsi.php', params)
        .then((response) => {
          this.items = response.data.imsi
          this.error = null
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    async executeReplaceImsi () {
      const params = createPostParams({
        action: 'replaceIccid',
        payload: {
          msisdn: this.search,
          iccidNew: this.iccidNew,
        },
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to replace new iccid was successful.'
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
