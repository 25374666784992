<template>
  <v-scale-transition>
    <wide-panel
      :title="title"
      :breadcrumbs="breadcrumbs"
    >
      <v-toolbar
        card
        extended
      >
        <v-container fluid>
          <v-layout row>
            <v-flex xs6>
              <v-text-field
                v-model="nohp"
                label="Enter search string"
                clearable
              />
            </v-flex>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="refreshList()"
              >
                Search
              </v-btn>
            </v-card-actions>
            <!-- <v-flex xs1>
              <v-spacer /><v-spacer />
              <v-checkbox
                v-model="xox"
                label="XOX"
              />
            </v-flex> -->
          </v-layout>
        </v-container>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="reject"
            :rows-per-page-items="[10, 25, 50, 100]"
            item-key="portreqformid"
            class="elevation-1"
            disable-initial-sort
          >
            <template #items="props">
              <tr>
                <td
                  v-if="props.item.msisdn"
                  class="text-xs-left"
                >
                  {{ props.item.msisdn }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.iccid"
                  class="text-xs-left"
                >
                  {{ props.item.iccid }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.donor"
                  class="text-xs-left"
                >
                  {{ props.item.donor }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.status === 2 || props.item.status === 1 || props.item.status === 0 || props.item.statusmsg === 'NPR Ack' || props.item.statusmsg === 'NPR'"
                  class="text-xs-center"
                >
                  Pending /{{ props.item.statusmsg }} /
                  {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.status === 5 && props.item.statusmsg === 'NPR Reject'"
                  class="text-xs-center"
                >
                  Rejected /{{ props.item.statusmsg }} /
                  {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.status === 7"
                  class="text-xs-center"
                >
                  Cancelled /{{ props.item.statusmsg }} /
                  {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.status === 9"
                  class="text-xs-center"
                >
                  Activated /{{ props.item.statusmsg }} /
                  {{ props.item.rejectcode }}
                </td>
                <td
                  v-if="props.item.lastUpdate"
                  class="text-xs-center"
                >
                  {{ props.item.lastUpdate }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td class="justify-center layout px-0">
                  <v-dialog
                    v-model="executeDialogReject"
                    max-width="500px"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        :disabled="executeRejectLoading ||shouldDisableRejectButton(props.item)"
                        small
                        color="red"
                        dark
                        class="mb-2"
                        v-on="on"
                        @click="mnpReject(props.item)"
                      >
                        Reject
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Execute Reject MNP on {{ props.item.msisdn }} </span>
                      </v-card-title>

                      <v-alert
                        :value="true"
                        :type="executeAlertType"
                        v-html="executeAlertMessage"
                      />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          :disabled="loading"
                          color="blue darken-1"
                          flat
                          @click="executeDialogReject = false"
                        >
                          {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
                        </v-btn>
                        <v-btn
                          v-show="executeAlertType === 'info'"
                          :loading="loading"
                          :disabled="loading"
                          color="blue darken-1"
                          flat
                          @click="executeMnpReject(props.item)"
                        >
                          Execute
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="executeDialogResubmission"
                    max-width="500px"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        :disabled="executeResubmissionLoading || shouldDisableResubmissionButton(props.item)"
                        small
                        color="orange"
                        dark
                        class="mb-2"
                        v-on="on"
                        @click="mnpResubmission(props.item)"
                      >
                        Resubmission
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Execute Resubmission Button MNP on {{ props.item.msisdn }} </span>
                      </v-card-title>

                      <v-alert
                        :value="true"
                        :type="executeAlertType"
                        v-html="executeAlertMessage"
                      />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          :disabled="loading"
                          color="blue darken-1"
                          flat
                          @click="executeDialogResubmission = false"
                        >
                          {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
                        </v-btn>
                        <v-btn
                          v-show="executeAlertType === 'info'"
                          :loading="loading"
                          :disabled="loading"
                          color="blue darken-1"
                          flat
                          @click="executeMnpResubmission(props.item)"
                        >
                          Execute
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'
import security from '@/security'
import { DateTime } from 'luxon'
// import Jimp from 'jimp'

const pageTitle = 'Mnp Reject'

export default {
  components: {
    WidePanel: () =>
      import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Mnp',
          disabled: false,
          to: '/support/mnp',
        },
        {
          text: pageTitle,
          disabled: true,
        },
      ],
      alert: false,
      alertType: null,
      alertMessage: null,
      maxDate: '',
      xox: null,
      error: null,
      day: null,
      nohp: null,
      id: null,
      idorder: null,
      refreshButtonDisabled: false,
      executeDialogReject: false,
      executeDialogResubmission: false,
      executeAlertType: 'info',
      executeAlertMessage: null,
      executeRejectLoading: false,
      executeResubmissionLoading: false,
      reject: [],
      resubmisson: [],
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'MSISDN',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: true,
          text: 'ICCID',
          value: 'iccid',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Donor',
          value: 'donor',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Status/StatusMsg/RejectCode',
          value: 'status',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Date',
          value: 'lastUpdate',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Actions',
          value: 'portreqformid',
        },
      ],
      items: [],
      onex: true,
      loading: false,
      snackbar: false,
      snackbarMessage: null,
      search: null,
      dialog: false,
      title: pageTitle,
      editedIndex: -1,
      editedItem: {
        nohp: null,
        portreqformid: null,
        idorder: null,
        status: null,
        statusmsg: null,
      },
      defaultItem: {
        nohp: null,
        portreqformid: null,
        idorder: null,
        status: null,
        statusmsg: null,
      },
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Reject' : 'Resubmission'
    },
  },
  watch: {
    xox: function (val) {
      this.onex = !val
    },
    executeDialogReject (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Click <strong>EXECUTE</strong> only when you are sure that the MNP status in SCRMS is relevant.</div>'
      }
    },
    executeDialogResubmission (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Click <strong>EXECUTE</strong> only when you are sure that there is no reject code available in the row.</div>'
      }
    },
  },
  mounted: function () {
    this.xox = security.me.claimer() === 'xox'
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.day = now.toFormat('yyyy-LL-dd')
  },
  methods: {
    mnpReject (portreqformid) {
      this.editedIndex = this.reject.indexOf(portreqformid)
      this.editedItem = Object.assign({}, portreqformid)
      this.executeDialog = true
    },
    async executeMnpReject () {
      try {
        const params = createPostParams({
          action: 'mnpReject',
          payload: {
            nohp: this.nohp,
            portreqformid: this.editedItem.portreqformid,
            idorder: this.editedItem.idorder,
          },
          executeResubmissionLoading: false,
        })

        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to reject an MNP was successful.'
        })
        // window.location.reload()
        await this.refreshList()
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
        this.executeRejectLoading = false
      }
    },
    mnpResubmission (portreqformid) {
      this.editedIndex = this.reject.indexOf(portreqformid)
      this.editedItem = Object.assign({}, portreqformid)
      this.executeDialog = true
    },
    async executeMnpResubmission () {
      try {
        this.executeResubmissionLoading = true
        const params = createPostParams({
          action: 'mnpResubmission',
          payload: {
            nohp: this.nohp,
            portreqformid: this.editedItem.portreqformid,
            idorder: this.editedItem.idorder,
          },
        })

        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request for MNP resubmission button was successful.'
        })
        await this.refreshList()
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
        this.executeResubmissionLoading = false
      }
    },

    getMnpReject () {
      return new Promise((resolve, reject) => {
        const params = createGetParams({
          nohp: this.nohp,
        })
        this.loading = true
        this.$rest
          .get('getMnpReject1.php', params)
          .then((response) => {
            this.reject = response.data.items
            this.reject.forEach(item => {
              this.id = item.portreqformid
              this.idorder = item.idorder
            })
            this.loading = false
            resolve(response.data)
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
            reject(error)
          })
      })
    },
    refreshList () {
      this.refreshButtonDisabled = true // Disable the button
      this.getMnpReject().then(data => {
        this.items = data.items
        this.refreshButtonDisabled = false // Re-enable the button after data refresh
      }).catch(error => {
        console.error('Error refreshing data:', error)
        this.refreshButtonDisabled = false // Re-enable the button if an error occurs
      })
    },
    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
    shouldDisableRejectButton (item) {
      return !(item.status === 2 || item.status === 1 || item.status === 0 || item.statusmsg === 'NPR Ack' || item.statusmsg === 'NPR')
    },
    shouldDisableResubmissionButton (item) {
      return item.showButton !== 1
    },
  },
}
</script>
